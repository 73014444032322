import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Header from "../separate/header";
import logo from "../../assets/site/logo.png";

export default function Privacy(props) {
  // const downloadLink = "http://localhost:3004/download/app.apk";
	const downloadLink = "https://api.swapout.biz/download/app.apk";
  return (
    <div className="App">
      <div class="banner">
        <div class="content">
          <div class="header">
            <Link to={"/"}>
							<img src={logo} alt="SwapOut Logo" class="logo" />
						</Link>
						<Link to={"/"} style={{textDecoration:"none"}}>
							<h1>SwapOut</h1>
						</Link>
          </div>
          <p class="description">
            SwapOut is your go-to app for all things crypto. Buy and sell
            cryptocurrencies, deposit and withdraw funds, stake your coins, and
            earn rewards through our referral program.
          </p>
          <div class="buttons">
            <Link to={downloadLink} target="_blank" class="btn download">
              Download Now
            </Link>
            {props.secName !="privacy" ?
            <Link to="/privacy" class="btn download">
              Privacy Policy
            </Link>:""}
            {props.secName !="terms" ?
            <Link to="/terms" class="btn download">
              Terms & Conditions
            </Link>:""}
            {props.secName !="accDel" ?
            <Link
                to="/account/delete"
                class="btn download"
              >
                Delete Account
            </Link>:""}
          </div>
          <div className="container" style={{ textAlign: "left" }}>
            <span>1. Introduction</span>
            <p>
              This Privacy Policy explains how SwapOut collects, uses,
              discloses, and protects your information when you use our
              services.
            </p>
            2. Information We Collect
            <p>
              Personal Information: We collect personal information such as your
              name, email address, phone number, and payment information when
              you register for an account or use our services.
            </p>
            <p>
              Transaction Information: We collect information about the
              transactions you perform on our platform, including buying,
              selling, depositing, withdrawing, and staking USDT.
            </p>
            <p>
              Usage Information: We collect information about your interaction
              with our services, including IP addresses, browser type, device
              information, and usage patterns.
            </p>
            3. How We Use Your Information
            <p>To provide, maintain, and improve our services</p>
            <p>To process transactions and send related information</p>
            <p>
              To communicate with you, including sending confirmations,
              technical notices, updates, and security alerts
            </p>
            <p>
              To prevent fraudulent transactions and ensure the security of our
              services
            </p>
            <p>To comply with legal and regulatory requirements</p>
            4. Sharing Your Information
            <p>
              We do not share your personal information with third parties
              except in the following circumstances:
            </p>
            <p>With service providers who perform services on our behalf</p>
            <p>
              To comply with legal obligations, law enforcement requests, and
              regulatory requirements
            </p>
            <p>To protect and defend the rights and property of SwapOut</p>
            <p>With your consent</p>
            5. Data Security
            <p>
              We implement appropriate technical and organizational measures to
              protect your personal information against unauthorized access,
              loss, destruction, or alteration.
            </p>
            6. Your Rights
            <p>
              You have the right to access, update, or delete your personal
              information.
            </p>
            7. Retention of Data
            <p>
              We retain your personal information for as long as necessary to
              provide our services, comply with our legal obligations, resolve
              disputes, and enforce our agreements.
            </p>
            8. Changes to this Privacy Policy
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on our
              website. You are advised to review this Privacy Policy
              periodically for any changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
