import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

import logo from "../../../assets/site/logo.png";

import Header from "../../separate/header";

import ReactPasswordToggleIcon from "react-password-toggle-icon";
import { useFormik } from "formik";
import * as yup from "yup";

import Config from "../../../core/config/";
import { toast, toastAlert } from "../../../core/lib/toastAlert";
import { makeRequest } from "../../../core/service/index";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

export default function AccDel(props) {
  // const downloadLink = "http://localhost:3004/download/app.apk";
  const downloadLink = "https://api.swapout.biz/download/app.apk";
  let inputRef = useRef();

  const showIcon = () => <AiOutlineEyeInvisible aria-hidden="true" />;
  const hideIcon = () => <AiOutlineEye aria-hidden="true" />;

  const [isLoading, setisLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (formik.values.otp == "") {
        formik.errors.otp = "Please enter valid Code";
        return false;
      }
      emailSubmit(values);
    },
  });

  const emailSubmit = async (values, target = "submit") => {
    setisLoading(true);
    const params = {
      url: `${Config.V1_API_URL}user/account/delete`,
      method: "POST",
      body: values,
    };
    const response = await makeRequest(params);
    setisLoading(false);
    let type = "error";
    if (response.status) {
      type = "success";
      formik.resetForm();
    }
    toast({ type, message: response.message });
  };

  return (
    <div className="App">
      <div class="banner">
        <div class="content">
          <div class="header">
            <Link to={"/"}>
              <img src={logo} alt="SwapOut Logo" class="logo" />
            </Link>
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <h1>SwapOut</h1>
            </Link>
          </div>
          <p class="description">
            SwapOut is your go-to app for all things crypto. Buy and sell
            cryptocurrencies, deposit and withdraw funds, stake your coins, and
            earn rewards through our referral program.
          </p>
          <div class="buttons">
            <Link to={downloadLink} target="_blank" class="btn download">
              Download Now
            </Link>
            {props.secName != "privacy" ? (
              <Link to="/privacy" class="btn download">
                Privacy Policy
              </Link>
            ) : (
              ""
            )}
            {props.secName != "terms" ? (
              <Link to="/terms" class="btn download">
                Terms & Conditions
              </Link>
            ) : (
              ""
            )}
            {props.secName !="accDel" ?
            <Link
                to="/account/delete"
                class="btn download"
              >
                Delete Account
            </Link>:""}
          </div>
          <div className="container" style={{ textAlign: "left" }}>
            <div>
              <form onSubmit={formik.handleSubmit}>
                <label className="mt-3 password-text-33">Email</label>
                {formik.values.email === "" ||
                !formik.values.email ||
                (formik.values.email && isNaN(formik.values.email) === true)
                  ? ""
                  : ""}
                <input
                  className="form-control "
                  type="text"
                  id="Email_Address"
                  name="email"
                  label="Email Address"
                  autoComplete="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                {formik.errors.email ? (
                  <small className="invalid-email error password-text-33">
                    {formik.errors.email} <br />
                  </small>
                ) : null}

                <br />

                <label className="mt-4 password-text-33">Password</label>
                <div
                  className="col"
                  style={{ position: "relative", display: "block" }}
                >
                  <input
                    className="form-control  input-dark"
                    type="password"
                    name="password"
                    label="Password"
                    autoComplete="off"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    ref={inputRef}
                  />
                  <ReactPasswordToggleIcon
                    inputRef={inputRef}
                    showIcon={showIcon}
                    hideIcon={hideIcon}
                  />
                </div>
                {formik.errors.password ? (
                  <small className="invalid-password error password-text-33">
                    {formik.errors.password} <br />
                  </small>
                ) : null}

                <button
                  type="submit"
                  className="btn text-white btn-col w-100 mt-4"
                  disabled={isLoading}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
