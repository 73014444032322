import React from "react";
import { Outlet } from "react-router-dom";

const ProtectedRoute = (props) => {
  return (
    <Outlet/>
  );
}

export default ProtectedRoute;
