import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Header from "../separate/header";
import logo from "../../assets/site/logo.png";

export default function Privacy(props) {
  // const downloadLink = "http://localhost:3004/download/app.apk";
	const downloadLink = "https://api.swapout.biz/download/app.apk";
  return (
    <div className="App">
      <div class="banner">
        <div class="content">
          <div class="header">
            <Link to={"/"}>
							<img src={logo} alt="SwapOut Logo" class="logo" />
						</Link>
						<Link to={"/"} style={{textDecoration:"none"}}>
							<h1>SwapOut</h1>
						</Link>
          </div>
          <p class="description">
            SwapOut is your go-to app for all things crypto. Buy and sell
            cryptocurrencies, deposit and withdraw funds, stake your coins, and
            earn rewards through our referral program.
          </p>
          <div class="buttons">
            <Link to={downloadLink} target="_blank" class="btn download">
              Download Now
            </Link>
            {props.secName !="privacy" ?
            <Link to="/privacy" class="btn download">
              Privacy Policy
            </Link>:""}
            {props.secName !="terms" ?
            <Link to="/terms" class="btn download">
              Terms & Conditions
            </Link>:""}
            {props.secName !="accDel" ?
            <Link
                to="/account/delete"
                class="btn download"
              >
                Delete Account
            </Link>:""}
          </div>
          <div className="container" style={{textAlign: "left"}}>
                <span>1. Introduction</span>
                <p>Welcome to SwapOut. By using our services, you agree to comply with and be bound by the following terms and conditions of use. Please review the following terms carefully.</p>

                2. Services
                <p>SwapOut provides the following services:</p>

                <p>Buying and selling of USDT</p>

                <p>Depositing and withdrawing with INR & USDT</p>

                <p>Staking feature</p>

                3. Eligibility
                <p>You must be at least 18 years old to use our services. By using SwapOut, you represent and warrant that you have the right, authority, and capacity to enter into this agreement and to abide by all of the terms and conditions.</p>

                4. Account Registration
                <p>To use SwapOut, you must register for an account and provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account and password.</p>

                5. User Conduct
                <p>You agree not to use the services for any unlawful or prohibited activities. You agree to comply with all applicable laws and regulations.</p>

                6. Fees and Payments
                <p>Fees for services provided by SwapOut are disclosed at the time of the transaction. You agree to pay all applicable fees and charges for your use of the services.</p>

                7. Transactions
                <p>All transactions made through SwapOut are final and non-refundable. You are responsible for verifying the accuracy of your transactions before confirming.</p>

                8. Staking
                <p>The staking feature allows you to earn rewards by locking up your USDT for a specified period. The terms and conditions for staking, including minimum amounts and reward rates, are subject to change and will be communicated to you through the app.</p>

                9. Limitation of Liability
                <p>SwapOut is not liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or the inability to use the services, unauthorized access to your account, or any other matter relating to the services.</p>

                10. Changes to Terms
                <p>SwapOut reserves the right to modify these terms at any time. Your continued use of the services following any changes indicates your acceptance of the new terms.</p>

                11. Governing Law
                <p>These terms are governed by and construed in accordance with the laws of the jurisdiction in which SwapOut operates.</p>
            </div>
        </div>
      </div>
    </div>
  );
}



