import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import logo from '../../assets/site/logo.png';

export default function Landing(props) {
	// const downloadLink = "http://localhost:3004/download/app.apk";
	const downloadLink = "https://api.swapout.biz/download/app.apk";
  return (
    <div className="App">
      <div class="banner">
        <div class="content">
          <div class="header">
						<Link to={"/"}>
							<img src={logo} alt="SwapOut Logo" class="logo" />
						</Link>
						<Link to={"/"} style={{textDecoration:"none"}}>
							<h1>SwapOut</h1>
						</Link>
          </div>
          <p class="description">
            SwapOut is your go-to app for all things crypto. Buy and sell
            cryptocurrencies, deposit and withdraw funds, stake your coins, and
            earn rewards through our referral program.
          </p>
          <div class="buttons">
						<Link to={downloadLink} target="_blank" class="btn download">
              Download Now
            </Link>
            {props.secName !="privacy" ?
            <Link to="/privacy" class="btn download">
              Privacy Policy
            </Link>:""}
            {props.secName !="terms" ?
            <Link to="/terms" class="btn download">
              Terms & Conditions
            </Link>:""}
						{props.secName !="accDel" ?
            <Link
                to="/account/delete"
                class="btn download"
              >
                Delete Account
            </Link>:""}
          </div>
        </div>
      </div>
    </div>
  );
}
