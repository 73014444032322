import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ProtectedRoute from "./core/lib/route.protected";

// import Landing from   "./Pages/Home/Landing";
import Homepage from   "./Pages/Home/Homepage";
import AccDelete from "./Pages/Account/Delete/AccDelete";
import Terms from "./Pages/CMS/Terms";
import Privacy from "./Pages/CMS/Privacy";

// css
import './assets/css/App.css';
import './assets/css/BasicForm.css';
import './assets/css/Homepage.css';

function App() {

  const routes = [
    // {
    //   path: "/",
    //   component: <Landing secName= "landing" />,
    // },
    {
      path: "/",
      component: <Homepage secName= "landing" />,
    },
    {
      path: "/account/delete",
      component: <AccDelete secName= "accDel" />
    },
    {
      path: "/terms",
      component: <Terms secName= "terms" />
    },
    {
      path: "/privacy",
      component: <Privacy secName= "privacy" />
    },
  ];

  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          {routes.map((data, index) => {
            return (
              <Route
                element={
                  <ProtectedRoute
                    authChk={data.authChk ? data.authChk : ""}
                  />
                }
              >
                <Route
                  onUpdate={() => window.scrollTo(0, 0)}
                  exact={true}
                  path={data.path}
                  element={data.component}
                  key={index}
                />
              </Route>
            );
          })}
        </Routes>
      </Router>
    </>
  );
}

export default App;
